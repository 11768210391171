// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/icons/NS.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/icons/NS.tsx");
  import.meta.hot.lastModified = "1710804628526.1062";
}
// REMIX HMR END

const ratio = 73 / 500;
export function NS({
  size = 500,
  ...rest
}) {
  return <svg width={size} height={size * ratio} viewBox='0 0 500 73' fill='none' {...rest}>
      <path d='M112.636 29.4332C118.495 29.4332 124.005 31.9442 124.005 40.0351V52.8689H117.821V40.4071C117.821 36.8731 115.914 34.8271 112.845 34.8271C109.497 34.8271 107.265 37.2916 107.265 40.8256V52.8689H101.081V40.8256C101.081 30.4911 109.613 29.4332 112.636 29.4332Z' fill='#17242F' />
      <path d='M190.202 29.4332C196.061 29.4332 199.688 33.1066 199.688 39.1981V52.8689H193.504V39.8956C193.504 36.6406 191.969 34.8271 189.226 34.8271C186.25 34.8271 184.25 36.9661 184.25 40.2211V52.8689H178.112V39.8956C178.112 36.6406 176.578 34.8271 173.834 34.8271C170.858 34.8271 168.859 36.9661 168.859 40.2211V52.8689H162.674V29.8982H168.022L168.44 32.5952C169.975 30.5957 172.3 29.4332 174.997 29.4332C178.159 29.4332 180.763 30.8747 182.158 33.2461C183.971 30.7352 186.668 29.4332 190.202 29.4332Z' fill='#17242F' />
      <path d='M221.356 29.3867C227.959 29.3867 232.516 34.3156 232.516 41.3836C232.516 48.4515 227.959 53.3804 221.356 53.3804C218.055 53.3804 215.265 52.2179 213.405 50.079L212.987 52.8689H207.639V20.3193H213.824V32.2232C215.684 30.4097 218.334 29.3867 221.356 29.3867ZM220.101 47.9865C223.774 47.9865 226.378 45.243 226.378 41.3836C226.378 37.5241 223.774 34.7806 220.101 34.7806C216.381 34.7806 213.777 37.5241 213.777 41.3836C213.777 45.243 216.381 47.9865 220.101 47.9865Z' fill='#17242F' />
      <path d='M250.084 29.4332C258.408 29.4332 263.011 34.9201 261.988 43.4295H244.272C244.691 46.3125 247.201 48.219 250.921 48.219C253.386 48.219 255.85 47.382 257.338 46.0335L260.779 49.5675C258.361 52.0319 254.688 53.4734 250.689 53.4734C242.924 53.4734 237.995 48.777 237.995 41.4301C237.995 34.2691 242.877 29.4332 250.084 29.4332ZM250.27 34.5016C247.015 34.5016 244.83 36.2686 244.365 39.3376H256.176C255.943 36.3616 253.711 34.5016 250.27 34.5016Z' fill='#17242F' />
      <path d='M281.146 29.4332C283.285 29.4332 285.517 30.1307 287.005 31.5257L284.308 36.3151C283.006 35.4316 281.89 35.1061 280.588 35.1061C277.194 35.1061 275.009 37.4776 275.009 41.6626V52.8689H268.824V29.8982H274.172L274.683 32.7811C276.45 30.4097 278.635 29.4332 281.146 29.4332Z' fill='#17242F' />
      <path d='M300.871 53.4734C296.872 53.4734 293.338 52.3109 290.409 50.2185L292.966 45.8475C295.105 47.3355 298.221 48.219 301.15 48.219C303.615 48.219 305.056 47.4285 305.056 46.08C305.056 44.685 303.801 44.034 300.639 43.755C294.733 43.29 291.199 40.6861 291.199 36.5476C291.199 32.2232 295.198 29.2937 301.336 29.2937C304.917 29.2937 308.125 30.2702 310.543 31.8977L308.032 36.1756C306.126 34.9666 303.987 34.4086 301.615 34.4086C298.732 34.4086 297.244 35.1526 297.244 36.5476C297.244 37.8031 298.407 38.4541 301.243 38.7331C307.893 39.3841 311.148 41.6626 311.148 46.08C311.148 50.544 307.195 53.4734 300.871 53.4734Z' fill='#17242F' />
      <path d='M340.123 53.4734C336.124 53.4734 332.591 52.3109 329.661 50.2185L332.219 45.8475C334.357 47.3355 337.473 48.219 340.402 48.219C342.867 48.219 344.308 47.4285 344.308 46.08C344.308 44.685 343.053 44.034 339.891 43.755C333.986 43.29 330.452 40.6861 330.452 36.5476C330.452 32.2232 334.451 29.2937 340.588 29.2937C344.169 29.2937 347.377 30.2702 349.795 31.8977L347.284 36.1756C345.378 34.9666 343.239 34.4086 340.867 34.4086C337.984 34.4086 336.496 35.1526 336.496 36.5476C336.496 37.8031 337.659 38.4541 340.495 38.7331C347.145 39.3841 350.4 41.6626 350.4 46.08C350.4 50.544 346.447 53.4734 340.123 53.4734Z' fill='#17242F' />
      <path d='M371.228 47.2425L372.856 52.0319C370.949 52.9154 369.275 53.2409 367.229 53.2409C362.533 53.2409 359.417 50.73 359.417 44.7315V34.9666H355.279V29.8982H359.417V24.0392L365.555 23.3882V29.8982H371.74V34.9666H365.555V44.7315C365.555 46.824 366.625 47.8935 368.485 47.8935C369.368 47.8935 370.438 47.6145 371.228 47.2425Z' fill='#17242F' />
      <path d='M396.805 29.8982H402.152V52.8689H396.805L396.386 50.079C394.48 52.2179 391.69 53.3804 388.435 53.3804C381.832 53.3804 377.275 48.4515 377.275 41.3836C377.275 34.3156 381.832 29.3867 388.435 29.3867C391.69 29.3867 394.526 30.5492 396.386 32.6417L396.805 29.8982ZM389.69 47.9865C393.41 47.9865 396.014 45.243 396.014 41.3836C396.014 37.5241 393.41 34.7806 389.69 34.7806C386.017 34.7806 383.413 37.5241 383.413 41.3836C383.413 45.243 386.017 47.9865 389.69 47.9865Z' fill='#17242F' />
      <path d='M424.603 47.2425L426.23 52.0319C424.324 52.9154 422.65 53.2409 420.604 53.2409C415.907 53.2409 412.792 50.73 412.792 44.7315V34.9666H408.654V29.8982H412.792V24.0392L418.93 23.3882V29.8982H425.114V34.9666H418.93V44.7315C418.93 46.824 419.999 47.8935 421.859 47.8935C422.743 47.8935 423.812 47.6145 424.603 47.2425Z' fill='#17242F' />
      <path d='M432.09 52.8689V29.8982H438.274V52.8689H432.09Z' fill='#17242F' />
      <path d='M457.56 53.3804C449.98 53.3804 445.144 48.405 445.144 41.3836C445.144 34.3621 449.98 29.3867 457.56 29.3867C465.139 29.3867 469.975 34.3621 469.975 41.3836C469.975 48.405 465.139 53.3804 457.56 53.3804ZM457.56 47.9865C461.373 47.9865 463.837 45.057 463.837 41.3836C463.837 37.7101 461.373 34.7806 457.56 34.7806C453.747 34.7806 451.282 37.7101 451.282 41.3836C451.282 45.057 453.747 47.9865 457.56 47.9865Z' fill='#17242F' />
      <path d='M142.605 53.0552C136.746 53.0552 131.236 50.5442 131.236 42.4533V29.6194L137.42 29.6194V42.0813C137.42 45.6153 139.326 47.6613 142.395 47.6613C145.743 47.6613 147.975 45.1968 147.975 41.6628V29.6194H154.16L154.16 41.6628C154.16 51.9973 145.627 53.0552 142.605 53.0552Z' fill='#17242F' />
      <path d='M488.445 29.6194C482.586 29.6194 477.076 32.1304 477.076 40.2213V53.0551H483.26V40.5933C483.26 37.0593 485.167 35.0133 488.236 35.0133C491.584 35.0133 493.816 37.4778 493.816 41.0118V53.0551H500V41.0118C500 30.6773 491.467 29.6194 488.445 29.6194Z' fill='#17242F' />
      <path d='M438.666 23.4693C438.666 25.3853 437.112 26.9385 435.196 26.9385C433.28 26.9385 431.727 25.3853 431.727 23.4693C431.727 21.5532 433.28 20 435.196 20C437.112 20 438.666 21.5532 438.666 23.4693Z' fill='#17242F' />
      <path fillRule='evenodd' clipRule='evenodd' d='M0.0174377 8.59489C0.0147351 2.75691 7.2556 0.0415371 11.0923 4.4417L30.2676 26.4331C28.1911 15.9793 33.9301 5.26228 44.2372 1.40826C55.69 -2.87413 68.4459 2.9386 72.7283 14.3914C73.8383 17.3599 74.271 20.4241 74.0993 23.4073L74.1182 64.2376C74.1209 70.0755 66.88 72.7909 63.0433 68.3907L43.8681 46.3994C45.9446 56.8531 40.2055 67.5702 29.8984 71.4242C18.4457 75.7066 5.68979 69.8938 1.4074 58.4411C0.297422 55.4726 -0.135278 52.4085 0.0363503 49.4254L0.0174377 8.59489ZM3.20338 49.5192L3.18442 8.59343C3.18308 5.68317 6.79268 4.32954 8.7053 6.52304L35.233 36.9466C37.2502 38.8652 38.8746 41.2595 39.9151 44.0424C43.5849 53.8568 38.6037 64.788 28.7892 68.4578C18.9748 72.1276 8.0436 67.1464 4.3738 57.3319C3.40992 54.7541 3.04284 52.0993 3.20338 49.5192ZM38.9026 35.8858L65.4304 66.3094C67.343 68.5029 70.9526 67.1493 70.9512 64.239L70.9323 23.3134C71.0928 20.7333 70.7258 18.0784 69.7619 15.5005C66.0921 5.68609 55.1609 0.704857 45.3464 4.37466C35.532 8.04446 30.5507 18.9756 34.2205 28.7901C35.2611 31.5729 36.8854 33.9672 38.9026 35.8858Z' fill='url(#paint0_linear_32_8066)' />
      <defs>
        <linearGradient id='paint0_linear_32_8066' x1='8.97365' y1='3.35921' x2='68.3546' y2='72.637' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#ADA3F4' />
          <stop offset='1' stopColor='#85E0A3' />
        </linearGradient>
      </defs>
    </svg>;
}
_c = NS;
var _c;
$RefreshReg$(_c, "NS");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;